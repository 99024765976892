//this file exists so the webpack build process will succeed
Ext._find = require('lodash.find');

Ext.xPusher = require('pusher-js');
Ext.xPowerBI = require('powerbi-client');

Ext.xBugsnag = require('@bugsnag/js');
Ext.xBugsnag.start({
    apiKey: '65cbc45142ea59b9889c6b9e11772843',
    appVersion: '4.0.9'
});
